import { toast } from "react-toastify";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";
import moment from "moment";
import { Checkbox } from "@mui/material";
import { color, minWidth, padding } from "@mui/system";

const myLib = require("./MyLib");
const server = require("./server");
/**Cac dinh nghia dung chung */
export const filterStyles = {
  option: (base, state) => ({
    ...base,
    fontSize: 12,
    padding: 2,
  }),
  control: (base, state) => {
    return {
      // none of react-select's styles are passed to <Control />
      ...base,
      minHeight: 20,
      border: state.selectProps.error ? "1px solid red" : undefined,
    };
  },
  input: (base) => ({ ...base, fontSize: 12 }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    fontSize: 13,
  }),
  indicatorsContainer: (base, state) => ({ ...base, maxHeight: 24 }),
  indicatorContainer: (base, state) => {
    return {
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: "yellow",
    };
  },
};
export const formStyles = (theme) => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blue: {
    color: "white",
    backgroundColor: "#0384fc",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
  colorBlue: {
    color: "blue",
  },
  timkiem: {
    backgroundColor: "red",
  },
  grow: {
    flexFlow: 1,
  },
  grid: {
    height: `${window.innerHeight - 150}px`,
    backgroundColor: "red",
  },
  alternateRow: {
    backgroundColor: "red",
  },
  iconLabelWrapper: {
    flexDirection: "row",
  },
  labelContainer: {
    width: "auto",
    padding: 0,
  },
  smallRadioButton: {
    "& svg": {
      width: "1.em",
      height: "0.6em",
    },
  },
});

export const loadDataError = (error, empty, log, trace) => {
  return !error ? (
    <b
      style={{
        padding: 8,
        border: "1px solid #7986cb",
        color: "#0000ff",
        borderRadius: 4,
      }}
    >
      {error ? log : empty ? "Không có bản ghi nào!" : ""}
    </b>
  ) : (
    <a
      href="#"
      style={{ textDecoration: "none" }}
      onClick={() =>
        toast.error(trace, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
      }
    >
      <b
        style={{
          padding: 8,
          border: "1px solid #7986cb",
          color: "red",
          borderRadius: 4,
        }}
      >
        {error ? log : empty ? "Không có bản ghi nào!" : ""}
      </b>
    </a>
  );
};
export const handleServerError = (error) => {
  var message = "";
  var log = "";
  try {
    var obj = JSON.parse(error);
    message = obj.message;
    log = obj.traceLog;
    var log_detail = JSON.parse(log);
    /*
    if (log_detail && log_detail.Message) {
      message += "\n<br>" + log_detail.Message;
      if (log_detail.ExceptionMessage) {
        message = log_detail.ExceptionMessage + "\n" + message;
      }
    }*/
    if (log_detail && log_detail.Error) {
      message = log_detail.Error.Message + ":\n" + message;
    }
  } catch (e) {
    message = error + "";
  }
  toast.error(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
  //console.log(error);
};
export const showError = (error) => {
  toast.error(error + "", {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
  console.log(error);
};
export const showSuccess = (error) => {
  toast.success(error + "", {
    position: "bottom-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};
export const showWarning = (error) => {
  toast.warning(error + "", {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};
export const showDone = (error) => {
  toast.info(error + "", {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};
//thong tin label tren phan trang grid
export const ReactDataGrid_i18n = Object.assign(
  {},
  ReactDataGrid.defaultProps.i18n,
  {
    showingText: "Đang hiển thị bản ghi ",
    pageText: "Trang:",
    ofText: "/",
    perPageText: "Bản ghi mỗi trang:",
  }
);
//cau hinh mac dinh cua grid
export const ReactDataGrid_default = (english) => {
  return {
    showColumnMenuTool: false,
    showZebraRows: true,
    i18n: english ? undefined : ReactDataGrid_i18n,
    multiSelect: false,
    showHoverRows: false,
    checkboxColumn: {
      render: (props) => (
        <input type="checkbox" readOnly checked={props.rowSelected}></input>
      ),
    },
    defaultLimit: 20,
    headerHeight: 30,
    rowHeight: 30,
    allowUnsort: true,
    skipLoadOnMount: false,
  };
};

export const themeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#2366c4",
    },
    secondary: {
      main: "#6C757D",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
  },
  typography: {
    body1: {
      lineHeight: 0.75,
      letterSpacing: "0.02em",
      fontSize: "0.8rem",
      fontWeight: 400,
    },
    caption: {
      lineHeight: 0.9,
    },
    body2: {
      fontSize: "12px", // size dòng datagrid
      fontFamily: "Roboto, Helvetica,Arial, sans-serif",
    },
    h6: {
      fontSize: "1.2rem",
    },
    h4: {
      letterSpacing: "0.05em",
    },
    subtitle1: {
      lineHeight: 1.09,
    },
    overline: {
      lineHeight: 0.92,
    },
  },
  spacing: 4,
};

export const componentOptions = {
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "black",
          fontsize: 13,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 2,
          "&-MuiAccordion-root.Mui-expanded": {
            marginBottom: 2,
            backgroundColor: "red",
          },
          backgroundImage: `url('/images/bgPageSub.jpg')`,
          color: "black !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          fontsize: 13,
          backgroundColor: "info",
        },
        startIcon: {
          "& > *:first-of-type": {
            fontSize: 13,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: "8px",
          lineHeight: 1,
          fontsize: 13,
          backgroundColor: "info",
          "& .MuiListItemIcon-root": {
            minWidth: "unset",
            marginRight: "4px",
          },
        },
        startIcon: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontsize: 13,
          padding: 0,
        },
        input: {
          padding: 4,
          fontsize: 13,
          "&.Mui-disabled": {
            backgroundColor: "#edebeb",
          },
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 36,
        },
        scroller: {
          backgroundImage: `url('/images/tab.jpg')`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 4,
          minHeight: 32,
          "&.Mui-selected": {
            color: "white",
          },
        },
        labelIcon: {
          flexDirection: "row",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 132,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
          padding: 6,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/caption.jpg')`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        region: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#5781de",
          color: "white",
          minHeight: 24,
          "&.Mui-expanded": {
            minHeight: 0,
          },
          "&-MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 24,
          },
          backgroundImage: `url('/images/caption.gif')`,
          backgroundPosition: `0px -31px`,
        },
        content: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          lineHeight: 1.2,
        },
      },
    },
    "& .MuiDataGrid-cell": {
      border: 1,
      borderRight: 0,
      borderTop: 0,
      // add more css for customization
    },

    MuiDataGrid: {
      styleOverrides: {
        virtualScroller: {
          "& webkit-.scrollbar": {
            width: "0.4em",
            height: "0.4em",
            backgroundColor: "#f1f1f1", // Change scrollbar color
          },
          "& webkit-.scrollbar-thumb": {
            backgroundColor: "#888",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
        },
        root: {
          // backgroundColor: "white",
          // padding: 6,
        },
        row: {
          "&:nth-of-type(odd)": {
            backgroundColor: "#e0f4ffa3", //màu đầu
          },
          "&:nth-of-type(even)": {
            backgroundColor: "#ffffff", // màu 2
          },
          "&:hover, &.Mui-hovered": {
            backgroundColor: "", // hover màu
            "@media (hover: none)": {
              backgroundColor: "",
            },
          },

          "&.Mui-selected": {
            backgroundColor: "#50a6a15e", // màu khi click
            // backgroundColor: "#f6f4ff", // màu khi click

            "&:hover, &.Mui-hovered": {
              backgroundColor: "#50a6a15e", // màu khi hover
              "@media (hover: none)": {
                backgroundColor: "#50a6a15e",
              },
            },
          },
        },
        columnHeader: {
          borderRight: "1px solid rgba(224, 224, 224, 1)",
          backgroundColor: "#254881",
        },
        columnHeaderRow: {
          backgroundColor: "#75829B",
        },
        columnHeaderTitle: {
          fontSize: "12px",
          fontWeight: "600",
          fontFamily: "Roboto, Helvetica,Arial, sans-serif",
          color: "#fff",
        },
        footerContainer: {
          minHeight: "30px",
          height: "30px",
        },
      },
    },
  },
};

export const scrollbarsDataGrid = {
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
    width: "0.4em",
    height: "0.4em",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
};
 

//thuc hien chuyen doi cau hinh form de them cac function can thiet
export const chuanHoaFormConfig = (formConfigs) => {
  formConfigs.map((item) => {
    let form = item.FormConfig;
    //form tim kiem can chuan lai dinh dang grid columns
    if (form.FormType === "Search") {
      form.GridColumns.map((column) => {
        if (column.dateFormat) {
          //dinh dang ngay thang thi them ham format
          column.valueGetter = (params) =>
            params.row[column.field]
              ? moment(params.row[column.field]).format(column.dateFormat)
              : null;
        }
        if (column.booleanFormat) {
          column.renderCell = (params) => (
            <Checkbox
              size="medium"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
              checked={params.row[column.field]}
              onChange={(event)=>column.onChange ? column.onChange(event, params.row) : null}
            />
          );
        }
      });
      form.SearchConfig.Fields.map((field) => {
        if (field.RadioGroup && field.RadioLabels.map === undefined && !myLib.isEmpty(field.RadioLabels)) {
          try
          {
            field.RadioLabels = JSON.parse(field.RadioLabels);
          }
          catch(e) {console.log(e);}
        }
      });
    }
  });
};
