import React, { useEffect, useState } from "react";
import HomePage from "./pages/HomePage";
import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";
import "./App.css";
import { ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import { showError, showWarning } from "./lib/common";
import { themeOptions } from "./lib/common";
import { componentOptions } from "./lib/common";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import viLocale from "date-fns/locale/vi";
const server = require("./lib/server");
const theme = createTheme({ ...themeOptions, ...componentOptions });
window.Buffer = require('buffer/').Buffer;
export default function App() {
  const [showVersion, setShowVersion] = useState(false);
  //kiem tra version
  useEffect(() => {
    server
      .getUrl("/version.txt")
      // .getUrl("http://localhost:3010/phanmem/" + "version.txt")
      .then((response) => {
        console.log("server version:" + response);
        if (response !== window.myconfig.SPAVersion) {
          window.myconfig.ServerVersion = response;
          setShowVersion(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        locale={viLocale}
        style={{ height: "100vh" }}
      >
        {showVersion ? (
          <div
            style={{
              backgroundColor: "yellow",
              color: "white",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10000,
            }}
          >
            <a href="#" onClick={() => window.location.reload(true)}>
              Click here to update version {window.myconfig.ServerVersion}
            </a>
          </div>
        ) : null}
        {/**Hien thi noi dung chuc nang */}
        <HomePage />
        <ToastContainer
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover={false}
          style={{ fontSize: 12, width: 550 }}
          limit={5}
        />
        
      </LocalizationProvider>
    </ThemeProvider>
  );
}
