/* Hien thi giao dien form tim kiem */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import MyDateEditor from "../components/MyDateEditor";
import { v4 as uuidv4 } from "uuid";
import { DataGrid, daDK, viVN } from "@mui/x-data-grid";
import { ToastContainer } from "react-toastify";
import { showError, showSuccess, handleServerError } from "../lib/common";
import moment from "moment";
import MySelectFilter from "../components/MySelectFilter";
import MenuConfig from "../DynamicForms/MenuConfig";
import ListFieldConfig from "../DynamicForms/ListFieldConfig";
import SearchFormConfig from "../DynamicForms/SearchFormConfig";
import DeleteConfirmDialog from "../components/DeleteConfirmDialog";
import EditForm from "../DynamicForms/EditForm";
import MyNumberEditor from "../components/MyNumberEditor";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import { HackFunctionList } from "../DynamicForms/FormHack";
import DownloadDialog from "../components/DownloadDialog";
import HS_Imports_Edit_Form from "./HS_Imports_Edit_Form";

const server = require("../lib/server");
const myLib = require("../lib/MyLib");

const scrollbarsDataGrid = {
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
    width: "0.4em",
    height: "0.4em",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
};

/* KHAI BAO MAIN FUNCTION */
export default function HS_Imports_Form(props) {
  //cac bien cau hinh form
  let FormConfig = props.FormConfig; //khai bao cau hinh hien thi form
  let SearchConfig = FormConfig.SearchConfig; //khai bao phan search filter
  let SearchButton = FormConfig.SearchButton;
  let ActionButtons = FormConfig.ActionButtons;
  //cac bien state tim kiem
  let defaultValues = {}; //gia tri khoi tao
  var hasDanhMuc = false;
  var danhMucNames = ""; //danh sach ten danh muc mac dinh
  let defaultDanhMuc = {};
  SearchConfig.Fields.map((field) => {
    if (field.DefaultValue !== undefined && field.DefaultValue !== null) {
      if (field.DefaultValue === "thisyear()") {
        defaultValues[field.FieldName] = new Date().getFullYear();
        defaultValues[field.FieldName + "_Ten"] = new Date().getFullYear();
      } else {
        if (
          field.DataType == "String" &&
          field.DefaultValue.indexOf("{") >= 0
        ) {
          var exp = field.DefaultValue.substring(
            1,
            field.DefaultValue.length - 1
          );
          var ps = exp.split(".");
          if (ps.length > 1 && ps[0] == "props") {
            defaultValues[field.FieldName] = props[ps[1]]; //lay tu props
          }
        } else {
          if (
            field.DataType === "Boolean" &&
            myLib.isEmpty(field.DefaultValue)
          ) {
            defaultValues[field.FieldName] = null;
          } else {
            defaultValues[field.FieldName] = field.DefaultValue;
          }
        }
      }
    }
    if (field.SourceName && field.SourceName !== "") {
      defaultDanhMuc[field.SourceName] = []; //khoi tao la array trong'
      hasDanhMuc = true;
      if (
        danhMucNames !== field.SourceName &&
        danhMucNames.indexOf(field.SourceName + ",") < 0 &&
        danhMucNames.indexOf("," + field.SourceName) < 0
      ) {
        danhMucNames += (danhMucNames !== "" ? "," : "") + field.SourceName;
      }
    }
  });
  const [DanhMuc, setDanhMuc] = React.useState(defaultDanhMuc); // du lieu danh muc se su dung trong form
  const [filterData, setFilterData] = React.useState(defaultValues);
  const [loading, setLoading] = React.useState(false); //trang thai loading du lieu
  //cac state dung cho datagrid
  const initialPagination = {
    page: 0,
    pageSize:
      parseInt(localStorage.getItem(props.FormID + "_" + "pageSize")) || 25,
  };
  //phan trang grid
  const [paginationModel, setPaginationModel] = useState({
    page: initialPagination.page,
    pageSize: initialPagination.pageSize,
  });
  const [forceReload, setForceReload] = React.useState(0); //su dung de yeu cau reload data
  const firstRenderRef = React.useRef(true); //danh dau trang thai first load
  const [sortModel, setSortModel] = useState({
    field: FormConfig.DefaultSortField ? FormConfig.DefaultSortField : "",
    sort: !FormConfig.SortDesc ? "asc" : "desc",
  }); //sap xep cua grid
  const [dataRows, setDataRows] = React.useState([]); //danh sach ban ghi
  const [dataCount, setDataCount] = React.useState(0); //tong so ban ghi
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null); //ban ghi dang duoc select
  //cac bien lien quan den show form
  const [actionForm, setActionForm] = React.useState({});
  const [showThemForm, setShowThemForm] = React.useState(false);
  const [showSuaForm, setShowSuaForm] = React.useState(false);
  const [showConfirmXoa, setShowConfirmXoa] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false); //trang thai dang thuc hien action xoa
  //cac bien lien quan den config
  const [showFilterConfig, setShowFilterConfig] = React.useState(false); //hien thi form edit cac tieu chi tim kiem
  const [showSearchFormConfig, setShowSearchFormConfig] = React.useState(false); //hien thi form config form tim kiem
  const [showGenCodeForm, setShowGenCodeForm] = React.useState(false);
  const [showConfirmExport, setShowConfirmExport] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...filterData };
    newData[fieldName] = value;
    setFilterData(newData);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj) {
    var newData = { ...filterData, ...obj };
    setFilterData(newData);
  }
  //xu ly su kien selection trong grid
  const onSelectionModelChange = (newSelectionModel) => {
    setRowSelectionModel(newSelectionModel);
    if (newSelectionModel.length > 0) {
      const selectedRowId = newSelectionModel[0];
      const find = dataRows.find((row) => row.id === selectedRowId);
      setSelectedRow(find);
    }
  };
  //xu ly su kien khi thay doi sort trong grid
  const onSortModelChange = (sortModel) => {
    if (sortModel.length !== 0) {
      setSortModel(sortModel[0]);
    }
  };

  //hook
  React.useEffect(() => {
    //thuc hien search on load form hoac khi co su thay doi ve phan trang va sap xep
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      if (danhMucNames !== "") {
        setLoading(true);
        server
          .post("Data/DoRequest", {
            Function: "Proc_Table_GetAll",
            ThamSo: {
              TableNames: danhMucNames,
            },
          })
          .then((response) => {
            setLoading(false);
            setDanhMuc(response);
            var newFilter = { ...filterData };
            //gan gia tri mac dinh tieu chi tim kiem
            SearchConfig.Fields.map((field) => {
              if (
                field.SourceName &&
                field.SourceName !== "" &&
                field.DefaultValue
              ) {
                var list = response[field.SourceName];
                if (field.DataType === "Int") {
                  var intValue = Number(field.DefaultValue);
                  var found = response[field.SourceName].find(
                    (item) => item[field.SourceValue] == intValue
                  );
                  if (found) {
                    newFilter = {
                      ...newFilter,
                      [field.FieldName]: intValue,
                      [field.FieldName + "_Ten"]: found[field.SourceLabel],
                    };
                  }
                }
                if (field.DefaultValue == "@FirstRow") {
                  newFilter = {
                    ...newFilter,
                    [field.FieldName]: list[0][field.SourceValue],
                    [field.FieldName + "_Ten"]: list[0][field.SourceLabel],
                  };
                }
              }
            });
            setFilterData(newFilter);
            if (FormConfig.SearchOnLoad) {
              setForceReload(true);
            }
          })
          .catch((error) => {
            handleServerError(error);
            setLoading(false);
          });
      } else {
        if (FormConfig.SearchOnLoad) {
          doSearch(); //load data khi load form
        }
      }
    } else {
      doSearch();
    }
    //ghi nho' gia tri pagesize
    localStorage.setItem(
      props.FormID + "_" + "pageSize",
      paginationModel.pageSize
    );
  }, [paginationModel, sortModel, forceReload]);

  //thuc hien tim kiem
  function doSearch() {
    setLoading(true);
    server
      .post("Data/DoRequest", {
        Function: FormConfig.SearchFunction,
        ThamSo: {
          PageIndex: paginationModel.page + 1,
          PageSize: paginationModel.pageSize,
          SortBy: 0,
          SortField: sortModel.field, // sorting server
          Ascending: sortModel.sort === "asc", // sorting server
          ...filterData,
        },
      })
      .then((response) => {
        const startStt = paginationModel.page * paginationModel.pageSize + 1; // lấy số trang hiện tại * số mục trong trang + 1
        const rowsWithId = response.DataList.map((row, index) => ({
          ...row,
          id: row[FormConfig.GridRowID] ?? uuidv4(),
          _STT: startStt + index, //page = 1 -> 1 2 3 4
        }));
        setDataRows(rowsWithId);
        setRowSelectionModel({});
        setSelectedRow(null); // reset lại lựa chọn
        setDataCount(response.DataCount[0].Total);
        setLoading(false);
      })
      .catch((error) => {
        handleServerError(error);
        setSelectedRow(null);
        setLoading(false);
      });
  }
  //thuc hien tao file export
  const doExport = () => {
    //thuc hien tai du lieu sau do update len server
    setIsDeleting(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_HS_Export_GetData",
        ThamSo: {
          ShipID: filterData.ShipID,
        },
      })
      .then((response) => {
        //tao cau truc du lieu file json
        var json = {
          ...response,
          DataNames: undefined,
        };
        const jsonText =  JSON.stringify(json);
        var raw = Buffer.from(jsonText, "utf8");
        var jsonRaw = Buffer.from(raw).toString("base64");
        //submit len server
        var filename =
          response.DM_Ship[0].ShipCode +
          "-" +
          moment(new Date()).format("YYYY-MM-DD") +
          ".json";
        server
          .post("Data/DoRequest", {
            Function: "Proc_HS_Exports_Update",
            ThamSo: {
              ShipID: filterData.ShipID,
              ShipCode: response.DM_Ship[0].ShipCode,
              FileContent: jsonRaw,
              FileName: filename,
            },
          })
          .then((response) => {
            setIsDeleting(false);
            setShowConfirmExport(false);
            setForceReload(forceReload + 1);
          })
          .catch((error) => {
            handleServerError(error);
            setIsDeleting(false);
          });
      })
      .catch((error) => {
        handleServerError(error);
        setIsDeleting(false);
      });
  };
  //thuc hien download
  const doDownload = () => {
    setDownloading(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_HS_Files_Get",
        ThamSo: {
          FileID: selectedRow.FileID,
        },
      })
      .then((response) => {
        const decoded = Buffer.from(response.HS_Files[0].FileContent, "base64");
        const blob = new Blob([decoded]);
        myLib.downloadBlob(blob, response.HS_Files[0].FileName);
        setDownloading(false);
      })
      .catch((error) => {
        handleServerError(error);
        setDownloading(false);
      });
  };
  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <Paper variant="outlined">
        <Grid container columns={24} spacing={1}>
          {SearchConfig.Fields.map((field) =>
            field.Hidden ? null : (
              <React.Fragment key={field.FieldName}>
                {field.LabelWidth ? (
                  <Grid item xs={field.LabelWidth ?? 4}>
                    <FormLabel>{field.Label}</FormLabel>
                  </Grid>
                ) : null}
                {field.InputWidth ? (
                  <Grid item xs={field.InputWidth ?? 4}>
                    {field.SourceName && field.SourceName !== "" ? (
                      <MySelectFilter
                        fullWidth
                        autoFocus={field.AutoFocus}
                        options={DanhMuc[field.SourceName]}
                        optionLabel={field.SourceLabel}
                        optionValue={field.SourceValue}
                        value={{
                          [field.SourceValue]: filterData[field.FieldName],
                          [field.SourceLabel]:
                            filterData[field.FieldName + "_Ten"],
                        }}
                        onChange={(value, selectedItem) => {
                          saveData({
                            [field.FieldName]:
                              value !== null ? value[field.SourceValue] : null,
                            [field.FieldName + "_Ten"]:
                              value !== null ? value[field.SourceLabel] : null,
                          });
                          if (field.AutoReload) {
                            setForceReload(new Date());
                          }
                        }}
                      />
                    ) : field.DataType === "Date" ? (
                      <MyDateEditor
                        fullWidth
                        autoFocus={field.AutoFocus}
                        value={filterData[field.FieldName]}
                        onChange={(value) => {
                          saveField(field.FieldName, value);
                          if (field.AutoReload) {
                            setForceReload(new Date());
                          }
                        }}
                      />
                    ) : field.DataType === "String" ? (
                      <OutlinedInput
                        fullWidth
                        autoFocus={field.AutoFocus}
                        value={filterData[field.FieldName]}
                        onChange={(event) => {
                          saveField(field.FieldName, event.target.value);
                          if (field.AutoReload) {
                            setForceReload(new Date());
                          }
                        }}
                      ></OutlinedInput>
                    ) : field.DataType === "Boolean" ? (
                      field.RadioGroup ? (
                        <RadioGroup
                          row
                          value={
                            myLib.isEmpty(filterData[field.FieldName])
                              ? null
                              : filterData[field.FieldName]
                          }
                          onChange={(event) => {
                            var v = event.target.value;
                            saveField(field.FieldName, v === "" ? null : v);
                            if (field.AutoReload) {
                              setForceReload(new Date());
                            }
                          }}
                        >
                          <FormControlLabel
                            value={null}
                            control={
                              <Radio
                                size="medium"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                              />
                            }
                            label={field.RadioLabels[0]}
                          />
                          <FormControlLabel
                            value={"true"}
                            control={
                              <Radio
                                size="medium"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                              />
                            }
                            label={field.RadioLabels[1]}
                          />
                          <FormControlLabel
                            value={"false"}
                            control={
                              <Radio
                                size="medium"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                              />
                            }
                            label={field.RadioLabels[2]}
                          />
                        </RadioGroup>
                      ) : (
                        <FormControlLabel
                          label={field.Label}
                          control={
                            <Checkbox
                              size="medium"
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                              checked={filterData[field.FieldName]}
                              onChange={(event) => {
                                saveField(
                                  field.FieldName,
                                  event.target.checked
                                );
                                if (field.AutoReload) {
                                  setForceReload(new Date());
                                }
                              }}
                            />
                          }
                        ></FormControlLabel>
                      )
                    ) : field.DataType === "Int" ? (
                      <MyNumberEditor
                        fullWidth
                        scale={0}
                        value={filterData[field.FieldName]}
                        onValueChange={(value) =>
                          saveField(field.FieldName, value)
                        }
                      ></MyNumberEditor>
                    ) : null}
                  </Grid>
                ) : null}
              </React.Fragment>
            )
          )}
          <Grid item xs={SearchButton.Width ?? 4}>
            {
              <Button
                variant="contained"
                fullWidth
                disabled={loading}
                onClick={doSearch}
              >
                Tìm kiếm
              </Button>
            }
          </Grid>
          <Grid item xs={1}>
            {loading ? <CircularProgress size={18} /> : null}
          </Grid>
        </Grid>
      </Paper>
      <Box
        sx={{
          height: "calc(100% - 8rem)",
          position: "relative",
        }}
      >
        <DataGrid
          rows={dataRows}
          rowCount={dataCount}
          columns={FormConfig.GridColumns}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onSelectionModelChange}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          sortingMode="server"
          onSortModelChange={onSortModelChange}
          rowHeight={28}
          columnHeaderHeight={28}
          localeText={viVN.components.MuiDataGrid.defaultProps.localeText}
          showCellVerticalBorder
          sx={scrollbarsDataGrid}
        />
      </Box>
      <Box variant="outlined" sx={{ padding: 2 }}>
        <Grid container columns={24} spacing={1}>
          {ActionButtons.map((button) => (
            <Grid item xs={button.Width ?? 2} key={button.Action}>
              <Button
                fullWidth
                variant="contained"
                disabled={
                  (button.Action.indexOf("Them") == -1 && !selectedRow) ||
                  !global.hasRoles(button.RightID)
                }
                onClick={() => {
                  //xu ly action them
                  if (button.Action.indexOf("Them") >= 0) {
                    //tim form trong du lieu config
                    let found = props.AllFormConfigs.find(
                      (form) => form.FormID === button.FormID
                    );
                    if (found) {
                      setShowThemForm(true);
                      setActionForm({
                        FormID: button.FormID,
                        FormConfig: found.FormConfig,
                      });
                    } else {
                      alert("Chưa khai báo form: " + button.FormID);
                    }
                  }
                  //xu ly action edit
                  else if (button.Action === "Download") {
                    doDownload();
                  }
                  //xu ly action xoa
                  else if (button.Action === "Xoa") {
                    //tim form trong du lieu config
                    setShowConfirmXoa(true);
                  } else if (button.Action === "Export") {
                    setShowConfirmExport(true);
                  } else {
                    //xu ly cac nut khac
                    let found = props.AllFormConfigs.find(
                      (form) => form.FormID === button.FormID
                    );
                    if (found) {
                      setShowThemForm(true);
                      setActionForm({
                        FormID: button.FormID,
                        FormConfig: found.FormConfig,
                      });
                    } else {
                      alert("Chưa khai báo form: " + button.FormID);
                    }
                  }
                }}
              >
                {button.Text}
              </Button>
            </Grid>
          ))}
          <Grid item xs={1}>
            {global.hasRoles(window.SoftCode + ".RX") ? (
              <MenuConfig
                handleMenuItemClick={(menu) => {
                  if (menu == "search") {
                    //Cau hinh truong thong tin tim kiem
                    setShowFilterConfig(true);
                  }
                  if (menu == "searchform") {
                    //cau hinh form tim kiem
                    setShowSearchFormConfig(true);
                  }
                  if (menu == "gencode") {
                    //hien thi form gencode
                    setShowGenCodeForm(true);
                  }
                }}
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>

      {/**hien thi form them moi */}
      {showThemForm ? (
        <HS_Imports_Edit_Form
          FormID={actionForm.FormID}
          FormConfig={actionForm.FormConfig}
          edittingRecord={null}
          FilterData={filterData}
          close={(ok) => {
            setShowThemForm(false);
            if (ok) {
              doSearch();
            }
          }}
        />
      ) : null}
      {/**Hien thi xac nhan export */}
      {showConfirmExport ? (
        <DeleteConfirmDialog
          title={"Xác nhận"}
          message={"Bạn muốn tạo file export ?"}
          isDeleting={isDeleting}
          handleDeleteAction={() => doExport()}
          handleCloseAction={() => setShowConfirmExport(false)}
        />
      ) : null}
      {/**Hien thi xac nhan xoa */}
      {showConfirmXoa ? (
        <DeleteConfirmDialog
          title={"Xác nhận"}
          message={"Bạn muốn xóa dữ liệu ?"}
          isDeleting={isDeleting}
          handleDeleteAction={() => {
            //thuc hien xu ly xoa
            setIsDeleting(true);
            server
              .post("Data/DoRequest", {
                Function: FormConfig.DeleteFunction,
                ThamSo: {
                  ...selectedRow,
                },
              })
              .then((response) => {
                setIsDeleting(false);
                setShowConfirmXoa(false);
                //thuc hien tim kiem
                doSearch();
              })
              .catch((error) => {
                handleServerError(error);
                setIsDeleting(false);
              });
          }}
          handleCloseAction={() => setShowConfirmXoa(false)}
        />
      ) : null}
      {
        /**Hien thi dialog doawnload
         */
        downloading ? (
          <DownloadDialog open={true} onRetry={() => doDownload()} />
        ) : null
      }
      {/**Hien thi form config tieu chi tim kiem */}
      {showFilterConfig ? (
        <ListFieldConfig
          FormID={props.FormID}
          FormConfig={FormConfig}
          close={() => setShowFilterConfig(false)}
        />
      ) : null}
      {
        /**Hien thi form cau hinh form tim kiem */
        showSearchFormConfig ? (
          <SearchFormConfig
            FormID={props.FormID}
            FormConfig={FormConfig}
            close={() => setShowSearchFormConfig(false)}
          />
        ) : null
      }
      {
        /**Hien thi form generate code sql */
        showGenCodeForm ? (
          <GenerateCodeDialog
            open={showGenCodeForm}
            FormID={props.FormID}
            FormConfig={FormConfig}
            close={() => setShowGenCodeForm(false)}
          />
        ) : null
      }
    </div>
  );
}
